import React from "react"
import { Global } from "@emotion/core"
import Footer from "./Footer"
import SEO from "./Seo"

const Layout = ({ children }) => (
    <React.Fragment>
        <Global
            styles={(theme) => ({
                "*": {
                    boxSizing: `inherit`,
                },
                html: {
                    fontSize: `18px`,
                    WebkitTextSizeAdjust: `100%`,
                },
                img: {
                    borderStyle: `none`,
                },
                pre: {
                    fontFamily: `monospace`,
                    fontSize: `1em`,
                },
                "[hidden]": {
                    display: `none`,
                },
                "::selection": {
                    background: theme.colors.text,
                    color: theme.colors.background,
                },
                "@media(max-width: 600px)": {
                    html: {
                        fontSize: `16px`,
                    },
                },
            })}
        />
        <SEO />
        {children}
        <Footer />
    </React.Fragment>
)

export default Layout