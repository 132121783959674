import React from "react"
import { Styled } from "theme-ui"

const SocialMedia = ({url}) => {
    return (
        <React.Fragment>
            <Styled.a href={url} target="_blank" rel="noopener">
                Instagram
            </Styled.a>
        </React.Fragment>
    )
}

export default SocialMedia