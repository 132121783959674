/** @jsx jsx */
import React from "react";
import { jsx, Box, Container, Flex, Link, useColorMode } from "theme-ui"
import SocialMedia from "./SocialMedia"
import ColorToggle from "./ColorToggle"
import {graphql, useStaticQuery} from "gatsby";

const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
      allPrismicSiteConfig {
        edges {
          node {
            id
            data {
              blog_title
              instagram {
                url
              }
              about_me {
                html
              }
            }
          }
        }
      }
    }
  `)

    const siteData = data.allPrismicSiteConfig.edges[0].node.data
    const [colorMode, setColorMode] = useColorMode()
    const isDark = colorMode === `dark`
    const toggleColorMode = e => {
        e.preventDefault()
        setColorMode(isDark ? `light` : `dark`)
    }

    return (
        <Box
            as="footer"
            variant="layout.footer"
            sx={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, ${isDark ? `0.35` : `0.15`}) 100%)`,
            }}
        >
            <Container>
                <div sx={{ display: `grid`, gridGap: 4, gridTemplateColumns: [`1fr`, `1fr`, `1fr`, `2fr 1fr`] }}>
                    <div
                        sx={{
                            p: { mb: 0 },
                            h2: {
                                mt: 0,
                                mb: 1,
                            },
                        }}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: siteData.about_me.html
                            }}/>
                    </div>
                    <Flex
                        sx={{
                            textAlign: [`center`, `center`, `center`, `right`],
                            flexDirection: `column`,
                            justifyContent: `space-between`,
                            marginBottom: '0.5rem'
                        }}
                    >
                        <ColorToggle isDark={isDark} toggle={toggleColorMode} />
                        <div sx={{ mt: [4, 4, 4, 0] }}>
                            <div sx={{ a: { ml: [1, 1, 1, 2], mr: [1, 1, 1, 0] }, marginBottom: '0.5rem' }}>
                                <SocialMedia url={siteData.instagram.url} />
                            </div>
                            <div sx={{ color: `textMuted` }}>&copy; {new Date().getFullYear()}. {siteData.blog_title}</div>
                        </div>
                    </Flex>
                </div>
            </Container>
            <Container
                sx={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    color: `text`,
                    fontWeight: `semibold`,
                    a: { color: `text` },
                    mt: 4,
                }}
            >
                <Link
                    aria-label="Link to Arno Solutions's website"
                    sx={{ ml: 2, fontSize: '14px' }}
                    href="https://arnosolutions.com"
                    target="_blank"
                    rel="noopener"
                >
                    Fait par Arno
                </Link>
            </Container>
        </Box>
    )
}

export default Footer